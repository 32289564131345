import "../../assets/styles/mypage.scss";
import Button from "../../components/common/Button";
import {useState, useContext, useRef} from "react";
import {ReactComponent as EditIcon} from "../../assets/icons/vuesax/bold/edit-2.svg";
import {ReactComponent as CameraIcon} from "../../assets/icons/vuesax/bold/camera.svg";
import PointImg from "../../assets/images/img_point.png";
import {ReactComponent as ArrowRight} from "../../assets/icons/arrow_right.svg";
import {ReactComponent as CouponIcon} from "../../assets/icons/coupon-icon.svg";
// import BadgeIcon from "../../assets/images/Badge.png";
import TrophyImg from "../../assets/images/trophy-dynamic.png";
import BadgeLowRank from "../../assets/icons/BadgeIcon1_4.png";
import BadgeMiddleRank from "../../assets/icons/BadgeIcon5_9.png";
import BadgeHighRank from "../../assets/icons/BadgeIcon10_19.png";
import {ReactComponent as LinkIcon} from "../../assets/icons/vuesax/outline/link-2.svg";
import {ReactComponent as CopyIcon} from "../../assets/icons/vuesax/outline/copy.svg";
import SelectMenu from "../../components/common/Select";
import ToastAlert from "../../components/common/Toast";
import ModalDialog from "../../components/common/Modal";
import {TextField} from "../../components/common/Input";
import {useNavigate} from "react-router";
import useSWR from "swr";
import {AuthContext} from "../../context/AuthContext";
import {useScript, useRunTimeEnvironment} from "../../utils/hooks";
import summonFriends from "../../services/summoner";


export default function MyPage() {

  const navigate = useNavigate();
  const RTE = useRunTimeEnvironment();
  const isLatestVersion = RTE === "APP"; // 최신 버전 태그: 놀이터 메인에서 강제 업데이트를 수행하므로, 버전비교 로직 생략함

  const [changePhotoOpen, setChangePhotoOpen] = useState(false); // 프로필 사진 변경 메뉴

  const [changeNameOpen, setChangeNameOpen] = useState(false); // 닉네임 변경 모달창
  const [alertMessage, setAlertMessage] = useState(false); // 토스트 메시지
  const [nickname, setNickname] = useState(""); // 닉네임 변경 대화상자 텍스트 필드 제어
  const [nameError, setNameError] = useState(false);

  const { API_SERVER_DOMAIN, useAccessToken, fetchWithToken, appInfo } = useContext(AuthContext);
  const accessToken = useAccessToken();
  const profileImage = useRef();
  
  const { data: profile, mutate } = useSWR(accessToken && [`${API_SERVER_DOMAIN}/user/profile/info`, accessToken], fetchWithToken);
  const { data: config } = useSWR(`${API_SERVER_DOMAIN}/auth/config`, url => fetch(url).then(res => res.json()));

  async function validateNickname() {
    if (nickname.length < 2 || nickname.length > 10) {
      setNameError(true);
    } else {
      const response = await fetch(`${API_SERVER_DOMAIN}/user/profile/nickname`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          nickname: nickname
        })
      });
      const jsonData = await response.json();
      if (jsonData.result === "ok") {
        mutate({ ...profile, info: { ...profile.info, nickname: jsonData.info.nickname }});
        setChangeNameOpen(false);
        setAlertMessage("닉네임 변경을 완료했어요.");
      } else {
        console.error(jsonData);
      }
    }
  }

  const writeClipboardText = async text => {
    try {
      await navigator.clipboard.writeText(text);
      setAlertMessage("복사 되었어요.");
    } catch (error) {
      console.error(error.message);
    }
  }

  const uploadProfileImage = async () => {
    const formData = new FormData();
    formData.append("image", profileImage.current.files[0]);

    const response = await fetch(`${API_SERVER_DOMAIN}/user/profile/image`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        // "Content-Type": "multipart/form-data",
      },
      body: formData
    });
    const jsonData = await response.json();

    if (jsonData.result === "ok") {
      mutate({ ...profile, info: { ...profile.info, profileImageUrl: `${jsonData.url}?v=${Math.floor(100000 + Math.random() * 900000)}` }});
      setAlertMessage("프로필 사진 변경을 완료했어요.");
    } else {
      console.error(jsonData);
      setAlertMessage(jsonData.message);
    }
  }

  const getBadgeIcon = winCount => {
    if (winCount < 1) {
      return TrophyImg;
    }
    
    if (winCount < 5) {
      return BadgeLowRank;
    }

    if (winCount < 10) {
      return BadgeMiddleRank;
    }

    return BadgeHighRank;
  }

  useScript("https://t1.kakaocdn.net/kakao_js_sdk/2.7.1/kakao.min.js");

  if (!profile || !config) return (<></>);

  return (
    <section className="my-page">
      <article>
        <div className="user-info">
          <img src={`${profile.info.profileImageUrl}`} alt="user-avatar" height={92} width={92}/>
          {/* <div className="camera-icon" onClick={() => setChangePhotoOpen(true)}> */}
          <label className="camera-icon" htmlFor="upload-avatar-image">
            <CameraIcon width={20} height={20}/>
            <input hidden ref={profileImage} onChange={() => uploadProfileImage()} type="file" id="upload-avatar-image" accept="image/png, image/jpeg" />
          </label>
          {/* </div> */}
          <div>
            <h2>{profile.info.nickname} <EditIcon width={24} onClick={() => setChangeNameOpen(true)}/></h2>
            <p className="text-light">{"야옹상회는 언제나\n야무지고 옹골찬 혜택이 한가득!"}</p>
          </div>
        </div>

        <div className="white-card points-and-coupons">
          <div>
            <img src={PointImg} alt={"points"} width={24}/>
            <span>포인트</span>
            <span>{`${profile.info.point.toLocaleString()}`}</span>
            <ArrowRight height={16} onClick={() => navigate("/user/points")}/>
          </div>
          <div>
            <CouponIcon width={24} />
            <span>쿠폰</span>
            <span>{`${profile.info.couponCount}`}</span>
            <ArrowRight height={16} onClick={() => navigate("/user/coupons", {state: {nickname: profile.info.nickname}})}/>
          </div>
        </div>

        <div className="white-card tory-game-victories">
          <div>
            <img src={getBadgeIcon(profile.info.winCount)} alt={"badge"} width={40}/>
            <span><b>토리를 구해줘 우승</b></span>
            <span className="text-accent">{`${profile.info.winCount.toLocaleString()}`}</span>
            <ArrowRight 
              height={16} 
              onClick={
                () => navigate("/user/victories", {state: {nickname: profile.info.nickname, winCount: profile.info.winCount}})
              }
            />
          </div>

        </div>

        <div className="white-card friend-invites">
          <div>
            <span>내 소환코드</span>
            <span>{profile.info.refCode}</span>
          </div>

          <div className="invites-info">
            <div>
              <span className="text-grey">소환한 친구</span>
              <span><strong>{profile.info.inviteBuddyCount}</strong>/3</span>
            </div>
            <div>
              <span className="text-grey">받은 포인트</span>
              <span><strong>{profile.info.receivedPoint.toLocaleString()}</strong></span>
            </div>
            <div />
          </div>

          <div className="invites-buttons">
            <Button onClick={() => summonFriends(1, profile.info.refCode)}>
              <LinkIcon height={20} style={{marginRight: 2}}/>
              <span>카카오톡</span>
            </Button>
            <Button onClick={() => {writeClipboardText(profile.info.refCode)}}>
              <CopyIcon height={20} style={{marginRight: 2}}/>
              <span>코드복사</span>
            </Button>
          </div>
        </div>

        <div className="white-card settings-list">
          <div>
            <span>친구 소환 코드 입력</span>
            <ArrowRight height={16} onClick={() => navigate("/user/summoner", {state: {refCode: profile.info.refCode}})}/>
          </div>
          <div>
            <span>알림/로그인 설정</span>
            <ArrowRight 
              height={16}
              onClick={
                () => navigate(
                  "/user/settings", 
                  {state: {
                    push: JSON.parse(profile.info.alertSetting), 
                    nickname: profile.info.nickname,
                    service: profile.info.loginType,
                    email: profile.info.email
                  }}
                )
              }
            />
          </div>
          <div>
            <span>공지사항</span>
            <ArrowRight height={16} onClick={() => navigate("/notifications")}/>
          </div>
          <div>
            <span>약관 및 개인정보 처리 방침</span>
            <ArrowRight height={16} onClick={() => navigate("/user/policy")}/>
          </div>
          <div>
            <span>앱 정보</span>
            {isLatestVersion && <span className="text-accent version-tag">최신</span>}
            <span className="text-light">
              {RTE === "APP" && appInfo.os === "ios" && appInfo.version + '.' + appInfo.buildVersion}
              {RTE === "APP" && appInfo.os === "android" && appInfo.version + '.' + appInfo.buildVersion}
              {RTE === "WEB" && "-"}
            </span>
          </div>
        </div>
      </article>

      <SelectMenu
        title="프로필 사진 변경"
        open={changePhotoOpen}
        setOpen={setChangePhotoOpen}
        items={["카메라", "사진선택"]}
        onSelect={(value) => {
          setChangePhotoOpen(false);
          setAlertMessage("프로필 사진 변경을 완료했어요.");
        }}
      />

      <ModalDialog open={changeNameOpen}
                   onCancel={() => {
                     setChangeNameOpen(false);
                   }}
                   title={"닉네임을 변경하세요."}>
        <TextField placeholder={"닉네임"}
                   defaultValue={profile.info.nickname}
                   onChange={(val) => {
                     if (nameError) setNameError(false);
                     setNickname(val);
                   }}
                   error={nameError}
                   errorText={nameError ? "한글 2~10글자로 입력하세요." : null}
                   maxLength={10}
                   style={{marginBottom: 20}}/>
        <div className="alert-action-btn">
          <Button className="prev-btn"
                  onClick={() => {
                    setChangeNameOpen(false);
                  }}>
            취소
          </Button>
          <Button onClick={validateNickname}>변경하기</Button>
        </div>
      </ModalDialog>
      <ToastAlert show={!!alertMessage} onClose={setAlertMessage} text={alertMessage} />

    </section>
  )
}
const KAKAO_APP_KEY = "9080647ab994e0c212ca0ec12a431e16";
const MOBILE_WEB_URL = "https://app.yaongstore.com/download";
const WEB_URL = "https://app.yaongstore.com/download";

export default function summonFriends(templateNo, refCode = "", token = "") {
  const [title, message, buttonText] = (() => {
    switch (templateNo) {
      case 1:
        return ["야옹상회 친구 200포인트 지급!!", "귀여운 야옹이 혜택놀이터 가입하고 200포인트 받기", "야옹상회 200P 받기"];
      case 2:
        return ["럭키 스핀 돌리고 수영장 공짜로 가자!", "신나게 돌리면 푸짐한 보상이 한 보따리! 무료 돌리기!", "럭키 스핀 무료 돌리기"];
    }
  })();

  if (typeof window.flutter_inappwebview !== "undefined") {
    window.flutter_inappwebview?.callHandler("kakao_share", title, message, templateNo);
  } else {
    if (window.Kakao) {
      const Kakao = window.Kakao;
      if (!Kakao.isInitialized()) {
        Kakao.init(KAKAO_APP_KEY);
      }
  
      Kakao.Share.sendDefault({
        objectType: "feed",
        content: {
          title: title,
          description: message,
          imageUrl: "https://app.yaongstore.com/share.png",
          link: {
            mobileWebUrl: `${MOBILE_WEB_URL}?ref=${refCode}`,
            webUrl: `${WEB_URL}?ref=${refCode}`
          }
        },
        buttons: [{
          title: buttonText,
          link: {
            mobileWebUrl: `${MOBILE_WEB_URL}?ref=${refCode}`,
            webUrl: `${WEB_URL}?ref=${refCode}`
          }
        }],
        serverCallbackArgs: {
          template_no: templateNo,
          access_token: token
        }
      });
    }
  }
}
import {ReactComponent as ArrowIcon} from "../../assets/icons/arrow_left.svg";
import {useNavigate, useLocation} from "react-router";
import {useState, useContext} from "react";
import TrophyImg from "../../assets/images/trophy-dynamic.png";
import BadgeIcon from "../../assets/images/Badge.png";
import BadgeLowRank from "../../assets/icons/BadgeIcon1_4.png";
import BadgeMiddleRank from "../../assets/icons/BadgeIcon5_9.png";
import BadgeHighRank from "../../assets/icons/BadgeIcon10_19.png";
import BadgeHighRank2 from "../../assets/icons/BadgeIcon20_49.png";
import BadgeHighRank3 from "../../assets/icons/BadgeIcon50_69.png";
import BadgeHighRank4 from "../../assets/icons/BadgeIcon70_89.png";
import ToryPic from "../../assets/images/image_placeholder.png";
import useSWR from "swr";
import {AuthContext} from "../../context/AuthContext";


export default function MyVictoriesPage() {

  const navigate = useNavigate();
  const {state} = useLocation();
  const [selectedTab, setSelectedTab] = useState(1);
  const hasBadge = true;
  const {API_SERVER_DOMAIN, useAccessToken, fetchWithToken} = useContext(AuthContext);
  const accessToken = useAccessToken();

  const {data: top10} = useSWR(accessToken && [`${API_SERVER_DOMAIN}/user/game/top10`, accessToken], fetchWithToken);
  const {data: history} = useSWR(accessToken && [`${API_SERVER_DOMAIN}/user/game/history`, accessToken], fetchWithToken);

  const getBadgeIcon = winCount => {
    if (winCount < 1) {
      return TrophyImg;
    }

    if (winCount < 5) {
      return BadgeLowRank;
    }

    if (winCount < 10) {
      return BadgeMiddleRank;
    }

    if (winCount < 20) {
      return BadgeHighRank;
    }

    if (winCount < 50) {
      return BadgeHighRank2;
    }

    if (winCount < 70) {
      return BadgeHighRank3;
    }

    return BadgeHighRank4;
  }

  /* const topList = [
    {nickname: "닉네임 입력", winCount: 10, rankIcon: BadgeHighRank},
    {nickname: "닉네임 입력", winCount: 10, rankIcon: BadgeHighRank},
    {nickname: "닉네임 입력", winCount: 10, rankIcon: BadgeMiddleRank},
    {nickname: "닉네임 입력", winCount: 10, rankIcon: BadgeMiddleRank},
    {nickname: "닉네임 입력", winCount: 10, rankIcon: BadgeLowRank}
  ]; */
  /* const myList = [
    {createdAt: "2024. 07. 13. 02:48", name: "토리를 구해줘",
      giftName: "[상품] 카르닉 스페이스 돔텐트 중형/대형 (아이보리)", hostTime: 4},
    {createdAt: "2024. 07. 13. 02:48", name: "토리를 구해줘",
      giftName: "[상품] 카르닉 스페이스 돔텐트 중형/대형 (아이보리)", hostTime: 5},
    {createdAt: "2024. 07. 13. 02:48", name: "토리를 구해줘",
      giftName: "[상품] 카르닉 스페이스 돔텐트 중형/대형 (아이보리)", hostTime: 6},
  ]; */

  if (!top10 || !history) return;

  return (
    <section className="victories-page">
      <div className="page-header header-shadow">
        <ArrowIcon onClick={() => navigate("/user")} style={{left: 24}}/>
        <h3>{state.nickname}님의 우승내역</h3>
      </div>

      <article>
        <img src={hasBadge ? getBadgeIcon(state.winCount) : TrophyImg} alt="point-icon-large" width={100} style={{margin: '32px 0 20px'}}/>
        <span className="text-grey" style={{fontSize: 15}}>총 우승 횟수</span>
        <span style={{fontSize: 32, fontWeight: 'bold'}}>{state.winCount}</span>
      </article>

      <article className="victory-history">
        <div className="basic-tabs">
          <div onClick={() => setSelectedTab(1)} className={selectedTab === 1 ? "selected-basic-tab" : ""}>
            <span>TOP 10</span>
          </div>
          <div onClick={() => setSelectedTab(2)} className={selectedTab === 2 ? "selected-basic-tab" : ""}>
            <span>나의 내역</span>
          </div>
        </div>

        <ul className="victories-list">
          {selectedTab === 1 &&
            // [...topList, ...topList].map((item, idx) => (
            top10.infos.map((item, idx) => (
              <li key={"victory-history-"+idx} className="top-victory-item">
                <div>
                  <div style={{gridRow: "1 / 3"}}>
                    <span>{1+idx}</span>
                  </div>
                  <div style={{gridRow: "1 / 3"}}>
                    <img src={getBadgeIcon(item.winCount)} alt="user-badge" height={56} style={{marginRight: 12}}/>
                  </div>
                  <span>{item.nickname}</span>
                  <span className="text-grey">누적 우승 횟수 {item.winCount}회</span>
                </div>
              </li>
          ))}

          {selectedTab === 2 && history.infos.map((item, idx) => (
            <li key={"victory-history-"+idx} className="victory-item">
             <div>
               <div style={{gridRow: "1 / 3"}}>
                 <img src={BadgeIcon} alt="user-badge" height={56}/>
               </div>
               <span className="text-light">{item.createdAt}</span>
               <span className="text-light">보유시간</span>
               <span>토리를 구해줘</span>
               <span>{item.hostTime}초</span>
             </div>
             <div>
               <p className="text-grey">{item.giftName ?? "[상품] 미획득"}</p>
             </div>
            </li>
          ))}
        </ul>
        {((selectedTab === 1 && top10.infos.length === 0) || 
          (selectedTab === 2 && history.infos.length === 0)) && 
          <article className="no-content">
            <img src={ToryPic} width={110} alt="no-list"/>
            <p>내용이 없어요.</p>
          </article>
        }
      </article>
    </section>
  )
}
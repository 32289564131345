import {ReactComponent as ArrowIcon} from "../../assets/icons/arrow_left.svg";
import React, {useContext, useRef} from "react";
import {ReactComponent as WinnerCupIcon} from "../../assets/images/winner-cup.svg";
import {useNavigate} from "react-router";
import AdViewImg from "../../assets/images/adv-view.png";
import Button from "../../components/common/Button";
import AdVideoImg from "../../assets/images/img_ad.png";
import {Autoplay, Pagination} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import {useState} from "react";
import PointImg from "../../assets/images/img_point.png";
import ToryEvent1 from "../../assets/images/banner_cat_1.png";
import ToryEvent2 from "../../assets/images/banner_cat_2.png";
import ToryEvent3 from "../../assets/images/image_placeholder.png";
import {ReactComponent as LinkIcon} from "../../assets/icons/vuesax/outline/link-2.svg";
import {ReactComponent as ShopIcon} from "../../assets/icons/vuesax/outline/shop.svg";
import QuestionIcon from "../../assets/icons/questicon.png";
import GameIntro from "../../assets/images/game-intro.png";
import {useEffect} from "react";
import ModalDialog, {AlertDialog} from "../../components/common/Modal";
import {BottomPopup} from "../../components/common/Popup";
import {AuthContext} from "../../context/AuthContext";
import useSWR from "swr";
import Loading from "../../assets/icons/Loading.png";
import {useScript, useRunTimeEnvironment} from "../../utils/hooks";
import ToastAlert from "../../components/common/Toast";
import {getPaidToWatchAds, useAdsListener} from "../../services/admob";
import summonFriends from "../../services/summoner";

const {
  format, 
  constructNow, 
  differenceInSeconds, 
  // differenceInMinutes, 
  differenceInDays, 
  // isSameDay, 
  getMonth,
  getDate,
  getHours, 
  getMinutes,
  isBefore,
  isAfter,
  subMinutes
} = require("date-fns");


export default function ToryGamePage() {
  // flutter에서 web으로 web측 함수 호출할때 사용되는 스크립트를 한군데에 모아뒀으니, 필요한 컴포넌트에서 해당 useScript만 복붙으로 사용하시면 되겠습니다.
  // 반드시 "window.addEventListener"를 이용해서 flutter와 상호작용 하려면 
  // useScript(`${process.env.PUBLIC_URL}/flutter/flutter_script.js`) 를 해당 Component에서 호출하며 사용하시면 되겠습니다.
  useScript(`${process.env.PUBLIC_URL}/flutter/flutter_script.js`)
  const [unityToss, setUnityToss] = useState();

  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(1);  
  const [alertMessage, setAlertMessage] = useState(false);
  const [pointAlertOpen, setPointAlertOpen] = useState(false); // 포인트 부족 메시지
  const [energyAlertOpen, setEnergyAlertOpen] = useState(false); // 에너지 부족 메시지
  const [matchInfoOpen, setMatchInfoOpen] = useState(false); // 안내 펍업창 열기
  const [gameIntroOpen, setGameIntroOpen] = useState(false); // 게임 소개
  const [adKey, setAdKey] = useState();
  const [adPath, setAdPath] = useState();
  const [gameRoomId, setGameRoomId] = useState();
  const [stepCount, setStepCount] = useState(0);
  const [gameState, setGameState] = useState(new Map());
  const { API_SERVER_DOMAIN, useAccessToken, fetchWithToken } = useContext(AuthContext);
  const accessToken = useAccessToken();
  const RTE = useRunTimeEnvironment();
  const gameStateObserver = useRef(null);
  const fetcher = url => fetch(url).then(res => res.json());

  // 사용자 정보
  const { data: gate, mutate: mutateInfo } = useSWR(accessToken && [`${API_SERVER_DOMAIN}/gate/info`, accessToken], fetchWithToken);
  const { data: profile } = useSWR(accessToken && [`${API_SERVER_DOMAIN}/auth/user/check`, accessToken], fetchWithToken);
  // 게임 목록
  const { data: activeGameRooms } = useSWR(`${API_SERVER_DOMAIN}/game/rooms?type=active`, fetcher);
  const { data: waitingGameRooms } = useSWR(`${API_SERVER_DOMAIN}/game/rooms?type=waiting`, fetcher);
  // 우승자 목록
  const { data: winners } = useSWR(`${API_SERVER_DOMAIN}/gate/prev/winners`, fetcher);

  const events = [
    {name: "토리를 구해줘", description: "광고 보고 에너지 충전!", content: "플레이 시 에너지 30% 이상 필요!", imgUrl: ToryEvent1},
    {name: "토리를 구해줘", description: "아이템 상점에서 쇼핑하세요.", content: "아이템으로 1등 도전!", imgUrl: ToryEvent2},
    {name: "토리를 구해줘", description: "토리를 가장 오래 보유하면 승리!", content: "토리 50m 접근 시 자동 뺏기!", imgUrl: ToryEvent3}
  ]

  const getPercent = (num) => {
    return Math.min(Math.floor(num/100), 100);
  }

  // 게암대기실 상태 판단
  const getGameState = (beginRunningTime, endRunningTime) => {
    // 게임 시작 1분전 active
    // 게임 시작 시간을 지나면 closed
    // console.info(differenceInSeconds(beginRunningTime, constructNow()));
    if (isAfter(constructNow(), beginRunningTime)) {
      return "closed";
    } else if (differenceInSeconds(beginRunningTime, constructNow()) > 60) {
      return "waiting";
    } else {
      return "active";
    }
  }

  // 게임대기실 상태 등록
  const registerGameState = (roomId, beginRunningTime, endRunningTime) => {
    let state = getGameState(beginRunningTime, endRunningTime);

    setGameState(prev => new Map(prev).set(roomId, { state: state, startTime: beginRunningTime, endTime: endRunningTime }));
  }

  // 게임대기실 상태 관측자 생성
  const createGameStateObserver = () => {
    if (!gameStateObserver.current && gameState.size > 0) {
      gameStateObserver.current = setInterval(() => {
        gameState.forEach((val, key) => {
          registerGameState(key, val.startTime, val.endTime);
        });
      }, 5000);
      return;
    }
  }

  // 게임 실행
  const runToryGame = async roomId => {
    const response = await fetch(`${API_SERVER_DOMAIN}/game/start`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        roomId: roomId
      })
    });
    const jsonData = await response.json();

    if (jsonData.result === "ok") {
      window.flutter_inappwebview.callHandler("goto_game", roomId);
    } else {
      setAlertMessage(jsonData.message);
    }
  }

  const step = async () => {
    // 걸음수 가져오는 방법입니다.
    const {step, date} = await window.flutter_inappwebview.callHandler("request_step");
    // console.log(`step:${step}`);  // int
    // console.log(`date:${date}`);  // String (2024-05-10)
    setStepCount(step);

    // 앱에서 Toast 호출하는 방법입니다.
    // [0]: toast
    // [1]: 문구
    // [2]: LONG (default SHORT) 필수값 아님.
    // window.flutter_inappwebview.callHandler("toast",`오늘 걸음 수: ${step}`,'LONG')
    // 이렇게 하면, default값으로 호출됩니다.
    // window.flutter_inappwebview.callHandler("toast",`오늘 걸음 수: ${step}`)

    // 앱에서 notification을 호출하는 방법입니다.
    // [0]: notify
    // [1]: 문구
    // window.flutter_inappwebview.callHandler("notify",`오늘 걸음 수: ${step}`)
  }

  // 보상형 광고 실행
  const startAds = async (path, type) => {
    if (type === "charge" && stepCount < 1000) {
      setAlertMessage("1,000걸음 이상부터 교환 가능해요.");
      return;
    }

    const response = await getPaidToWatchAds(RTE, `${API_SERVER_DOMAIN}${path}`, accessToken, type);
    if (response.adKey) {
      setAdKey(response.adKey);
      setAdPath(path);
    } else {
      setAlertMessage(response.message);
    }
  }
  // 광고 시청 보상 지급
  const adsCallback = () => {
    mutateInfo();
    step();
  }
  useAdsListener(`${API_SERVER_DOMAIN}${adPath}`, accessToken, {adKey: adKey}, adsCallback);

  useEffect(() => {
    // console.log(gameState.size);
    createGameStateObserver();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState.size]);

  useEffect(() => {
    // 오늘 진행중 게임 상태 등록
    activeGameRooms?.infos?.forEach(room => {
      // console.log(room.roomId);
      registerGameState(room.roomId, room.beginRunningTime, room.endRunningTime);
    });
  }, [activeGameRooms?.infos?.length]);

  useEffect(() => {
    document.getElementsByClassName("page-container")[0]?.scrollTo(0, 0);

    if (RTE === "APP") {
      step();
    }

    // 외부 이벤트를 수신하여 React 상태를 업데이트
    const handleExternalChange = ({detail}) => {
      setUnityToss(detail.unityToss);
      // alert(`앱에서 특정 신호가 도착했습니다. (${detail.unityToss})`)
      /**
       * TODO: Flutter 코드인데, 설명을 드리겠습니다.
       * ====================================================================================
       * if(message == 'TimeLimit'){
       *   Fluttertoast.showToast(msg: '[시간지연] 게임 자동 종료');
       *   webViewController!.evaluateJavascript(source: "notifyUnityToss('$message')");
       * }else if(message == 'EndRewardOutside'){
       *   webViewController!.evaluateJavascript(source: "notifyUnityToss('$message')");
       * }else if(message == 'EndRewardInside') {
       *   webViewController!.evaluateJavascript(source: "notifyUnityToss('$message')");
       * }
       * ====================================================================================
       * handleExternalChange => event.detail.unityToss 로 'TimeLimit', 'EndRewardOutside', 'EndRewardInside' String값이 들어올겁니다.
       * 상황에 맞게 배분해주시면 되겠습니다.
       **/ 

      if(detail.unityToss == 'TimeLimit'){
        // 시간 지연 팝업을 웹에서 띄워주시면 되겠습니다.
        alert("시간 지연으로 인한 종료. (안내 메시지 추가 필요)");
        
      }else if(detail.unityToss == 'EndRewardOutside'){
        // 배송지 입력으로 이동하면 되겠습니다.
        // ToDo: 게임방ID(roomId) 필요
        navigate("/tory-game-end");
      }else if(detail.unityToss == 'EndRewardInside'){
        // 쿠폰함으로 이동하면 되겠습니다.
        navigate("/user/coupons", {state: {nickname: profile.userInfo.nickName}});
      }
    };

    // const adsExternalChange = ({detail}) => {
      /**
       * TODO: 주의!
       * 
       * getPaidToWatchAds() 함수가 실행되는 모든 Component에 window.addEventListener('ads', adsExternalChange); 를 주입하거나,
       * redux같은 부분에서 DOM으로 핸들링 가능하게 하거나 하셔야 될 거 같은데, 보일러플레이트 우려가 있으니, 해당 부분은 참고로 안내드립니다.
       * 
       **/
      /* if(detail.event == 'end'){
        // 광고 끝나면, 여기로 들어오게됩니다.
        // ${detail.reward} 값으로 핸들링하세요!
        console.log('reward',detail.reward); // Integer Type
      }else if(detail.event == 'charge'){
        // 광고 끝나면, 여기로 들어오게됩니다.
        // charge 이벤트는 에너지 전환 관련 이벤트입니다.
        console.log('reward',detail.reward); // Integer Type (현재 걸음 수가 5621일 경우, 5000만 넘어옵니다.)
        // 1000걸음 미만일 결우, 광고 시청이 불가능하도록 react에서 구현이 필요합니다.

        // react UI 걸음 수 갱신을 위해, 한번 더 step 함수를 호출해야겠습니다.
        step();
      } */
    // };
    // 외부 이벤트를 수신할 때마다 이벤트 리스너를 등록
    window.addEventListener('unityToss', handleExternalChange);
    // window.addEventListener('ads', adsExternalChange);

    // 앱(or 브라우저)이 다시 활성화 되면 게임대기실 상태 감지
    window.addEventListener("focus", createGameStateObserver);
    // 컴포넌트가 언마운트될 때 이벤트 리스너를 해제
    return () => {
      window.removeEventListener('unityToss', handleExternalChange);
      // window.removeEventListener('ads', adsExternalChange);
      
      window.removeEventListener("focus", createGameStateObserver);
      clearInterval(gameStateObserver.current);
      gameStateObserver.current = null;
    };
  }, []);


  if (!gate || !activeGameRooms || !waitingGameRooms || !profile || !winners) return <LoadingIcon />;

  return (
    <section className="tory-game-enter">
      <div className="page-header">
        <ArrowIcon onClick={() => navigate("/playground")} style={{left: 24}}/>
        <h3>토리를 구해줘</h3>
      </div>


      <div className="game-winner-info">
        <div>
          {winners.infos
            .filter((el, i) => winners.infos.indexOf(el) === i)
            .map((winner, i) => 
            <React.Fragment key={winner}>
              {i === 0 && 
                <>
                  <WinnerCupIcon/>
                  <p>[저번주 우승자]</p>
                </>
              }
              <p>{winner}님</p>
            </React.Fragment>
          )}
        </div>
      </div>

      <article>
        <div className="ad-view-box" style={{margin: "16px 0", width: "100%"}}>
          <img src={AdViewImg} alt={"points"} width={40} height={40}/>
          <div>
            <p>광고 시청하고 (1회 시청당 에너지 10%)</p>
            <p>무료 에너지를 얻으세요</p>
          </div>
          <Button onClick={() => {startAds("/gate/ad/energy", "");}}>
            <img src={AdVideoImg} alt="video-icon" height={20}/>
            <span>받기</span>
          </Button>
        </div>

        <div className="steps-card white-card">
          <h3>오늘 {profile.userInfo.nickName}님의 걸음 수</h3>
          <span className="text-accent" style={{fontSize: 28}}>{stepCount.toLocaleString()}</span>
          <p className="text-grey">오늘의 걸음을 에너지 <b>{getPercent(stepCount)}%</b>로 바꾸세요!</p>
          <div className="steps-progress-bar"
               style={{background: `linear-gradient(90deg, #f6475f 0%, #f6475f ${gate.info.energy}%, #EBEBEB ${gate.info.energy}%, #EBEBEB 100%)`}}>
            <span className="energy-percent"
                  style={{
                    left: gate.info.energy <= 85 ? `calc(${gate.info.energy}% + 8px)` : "80%",
                    color: gate.info.energy <= 85 ? "var(--accent)" : "white"
                  }}>
              {gate.info.energy}%
            </span>
          </div>
          <p className="text-grey">에너지100%(10,000걸음)</p>
          <Button onClick={() => {startAds("/gate/ad/energy/walk", "charge");}}>
            <img src={AdVideoImg} alt="video-icon" height={20}/>
            <span>광고시청 후 바꾸기</span>
          </Button>
        </div>

        <Swiper pagination={true} modules={[Pagination, Autoplay]}
                spaceBetween={4} autoplay={{delay: 3000}} loop={true}
                className="tory-img-swiper">
          {events.map((event, idx) => (
            <SwiperSlide key={"event-slide-" + idx}>
              <EventSlide
                name={event.name}
                description={event.description}
                content={event.content}
                imgUrl={event.imgUrl}
                linkUrl={event.linkUrl}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="game-area-header">
          <h3>토리를 구하러 출발!</h3>
          <img src={QuestionIcon} alt={"question-icon"} width={24} height={24} onClick={() => setGameIntroOpen(true)}/>
          <button className="info-btn" onClick={() => setMatchInfoOpen(true)}>안내</button>
        </div>

        <div className="white-card game-area-cards">
          <div className="basic-tabs">
            <div onClick={() => setSelectedTab(1)} className={selectedTab === 1 ? "selected-basic-tab" : ""}>
              <span>오늘 진행중</span>
            </div>
            <div onClick={() => setSelectedTab(2)} className={selectedTab === 2 ? "selected-basic-tab" : ""}>
              <span>진행 예정</span>
            </div>
          </div>
          <article>
            {(selectedTab === 1 && gameState.size > 0) &&
              // 시작 전, 현재 진행 중
              // gameRooms.infos.filter(room => isSameDay(room.beginRunningTime, constructNow())).map(room => {
              activeGameRooms.infos.filter(room => isBefore(constructNow(), room.beginRunningTime)).map(room => {
                const goodsInfo = JSON.parse(room.goodsInfo);
                return <GameDealCard
                  key={room.roomId}
                  remainedCount={0}
                  status={gameState.get(room.roomId)}
                  imgUrl={encodeURI(goodsInfo.IMG_URL)}
                  entryFee={room.entryFee}
                  refCode={profile.userInfo.refCode}
                  title={"[토리를 구해줘] 최대한 오래 토리를 구조하고 무료로 상품을 가져가세요"}
                  description={goodsInfo.GOODS_NAME}
                  onClick={
                    () => {
                      if (RTE === "APP") {
                        // 에너지 30% 미만 경고
                        if (gate.info.energy < 30) {
                          setGameRoomId(room.roomId);
                          setEnergyAlertOpen(true);
                          return;
                        }
                        runToryGame(room.roomId);
                      } else {
                        setAlertMessage("게임은 앱에서만 가능해요");
                      }
                    }
                  }
                />
              })
            }
            {(selectedTab === 1 && gameState.size > 0) &&
              // 종료됨
              // gameRooms.infos.filter(room => isSameDay(room.beginRunningTime, constructNow())).map(room => {
              activeGameRooms.infos.filter(room => isAfter(constructNow(), room.beginRunningTime)).map(room => {
                const goodsInfo = JSON.parse(room.goodsInfo);
                return <GameDealCard
                  key={room.roomId}
                  remainedCount={0}
                  status={gameState.get(room.roomId)}
                  imgUrl={encodeURI(goodsInfo.IMG_URL)}
                  entryFee={room.entryFee}
                  refCode={profile.userInfo.refCode}
                  title={"[토리를 구해줘] 최대한 오래 토리를 구조하고 무료로 상품을 가져가세요"}
                  description={goodsInfo.GOODS_NAME}
                  onClick={() => {}}
                />
              })
            }
            {(selectedTab === 1 && activeGameRooms.infos.length === 0) &&
              <article className="no-content">
                <img src={ToryEvent3} width={110} alt="no-available-coupon"/>
                <p>내용이 없어요.</p>
              </article>
            }

            {(selectedTab === 2 && waitingGameRooms.infos.length > 0) &&
              // gameRooms.infos.filter(room => isBefore(room.exposureDay, constructNow()) && differenceInDays(room.beginRunningTime.substring(0, 10), format(new Date(), "yyyy-MM-dd")) > 0).map(room => {
              waitingGameRooms.infos.map(room => {
                const goodsInfo = JSON.parse(room.goodsInfo);
                return <GameDealCard
                  key={room.roomId}
                  remainedCount={0}
                  status={{ state: "coming", startTime: room.beginRunningTime, endTime: "" }}
                  dayCount={differenceInDays(room.beginRunningTime.substring(0, 10), format(new Date(), "yyyy-MM-dd"))}
                  imgUrl={encodeURI(goodsInfo.IMG_URL)}
                  entryFee={room.entryFee}
                  refCode={profile.userInfo.refCode}
                  title={"[토리를 구해줘] 최대한 오래 토리를 구조하고 무료로 상품을 가져가세요"}
                  description={goodsInfo.GOODS_NAME}
                  onClick={f => f}
                />
              })
            }
            {(selectedTab === 2 && waitingGameRooms.infos.length === 0) &&
              <article className="no-content">
                <img src={ToryEvent3} width={110} alt="no-available-coupon"/>
                <p>내용이 없어요.</p>
              </article>
            }
          </article>
        </div>
      </article>

      <ToastAlert show={!!alertMessage} onClose={setAlertMessage} text={alertMessage} />

      <ModalDialog open={pointAlertOpen}
                   title={"포인트 안내"} className="alert-modal"
                   onCancel={() => setPointAlertOpen(false)}>
        <p className="alert-msg">포인트가 부족해요. 광고 시청을 통해 포인트를 획득하세요.</p>
        <div className="alert-action-btn">
          <Button className="prev-btn" onClick={() => setPointAlertOpen(false)}>닫기</Button>
          <Button  onClick={() => setPointAlertOpen(false)}>광고시청</Button>
        </div>
      </ModalDialog>

      <ModalDialog open={gameIntroOpen}
                   title={"게임 소개"} className="alert-modal game-intro-modal"
                   onCancel={() => setGameIntroOpen(false)}>
        <img src={GameIntro} alt="game-intro"/>
        <div className="alert-action-btn">
          <Button onClick={() => setGameIntroOpen(false)}>닫기</Button>
        </div>
      </ModalDialog>

      <AlertDialog 
        open={energyAlertOpen}
        className="energy-modal"
        title="안내"
        onClose={() => setEnergyAlertOpen(false)}
        onCancel={() => {
          setEnergyAlertOpen(false);
        }}
        onOk={() => {
          setEnergyAlertOpen(false);
          runToryGame(gameRoomId);
        }}
        buttonText="입장하기"
        buttonSecondText="나가기"
        text="에너지가 부족해요. 광고 시청으로 에너지를 채운 후 입장하세요. 에너지는 최소 30%이상 보유해야 3분 플레이가 가능해요. 부족해도 입장 하시겠어요?"
      />

      <BottomPopup title="경기장 이용 안내"
                   open={matchInfoOpen}
                   setOpen={setMatchInfoOpen}>
        <ol className="match-info text-grey">
          <li>동일한 시간 50명이 각자의 방에서 플레이 (플레이 타임 : 3분)</li>
          <li>1분 마다 10%의 에너지 차감.</li>
          <li>오늘의 걸음은 광고 시청 1번으로 모두 에너지로 변환, 부족 시 광고 시청으로 보충</li>
          <li>가장 오랜 시간 토리를 소유한 사람이 우승자!</li>
        </ol>
      </BottomPopup>
    </section>
  )
}


function EventSlide({name, description, content, imgUrl, linkUrl}) {

  const navigate= useNavigate();

  function handleClick() {
    if (linkUrl) navigate(linkUrl);
  }

  return (
    <div className="event-slide" onClick={handleClick}>
      <span>{name}</span>
      <p>{description}</p>
      <p>{content}</p>
      {imgUrl && <img src={imgUrl} alt={name} height={90}/>}
    </div>
  )
}


function GameDealCard(props) {

  const {
    remainedCount = 0, status, imgUrl = "",
    title = "", description = "", onClick = () => {},
    refCode, entryFee = 0, dayCount = 0
  } = props;
  const navigate = useNavigate();

  const getStatus = (st) => {
    switch (st) {
      case "active":
        return "진행중";
      case "waiting":
        return "오픈 예정";
      case "coming":
        return "진행 예정";
      case "closed":
        return "종료됨";
      default:
        return "진행중";
    }
  };

  const remainedTime = (() => {
    let remainedTimeString = '';
    switch (status.state) {
      case "active":
        return "게임이 진행중이에요.";
      case "coming":
        // return `${dayCount} Day${dayCount > 1 ? "s" : ""}`;
        remainedTimeString = `${getMonth(status.startTime) + 1}월 ${getDate(status.startTime)}일 `;
      case "waiting":
        const startTime = subMinutes(status.startTime, 1);
        return remainedTimeString += `${getHours(startTime) < 12 ? "오전" : "오후"} ${getHours(startTime) > 12 ? getHours(startTime) - 12 : getHours(startTime)}:${getMinutes(startTime) > 9 ? getMinutes(startTime) : "0" + getMinutes(startTime)} 오픈 예정`;
      default:
        return "게임이 종료되었습니다.";
    }
  })();

  function enterGame() {
    onClick();
  }

  return (
    <div className="deal-card">
      <div onClick={() => navigate("/playground/reward", {state: {imgUrl, title, description, remainedTime}})}>
        {/* <span>{remainedCount.toLocaleString()}개 남음</span> */}
        <div className="card-img" style={{background: `url(${imgUrl}) no-repeat`}}/>
        <div>
          <img src={PointImg} alt={"points"} width={24}/>
          <p>입장비 {entryFee.toLocaleString()}포인트</p>
        </div>
      </div>
      <span className="deal-status">{getStatus(status.state)}</span>

      <h4>{title}</h4>
      <p>{description}</p>

      <Button disabled={status.state !== "active"} style={{marginBottom: 12}} onClick={enterGame} className={`bg-color-${status.state}`}>
        {status.state === "active" ? "바로 입장하기" : remainedTime}
          {/* status.state === "coming" ? `${dayCount} Day${dayCount > 1 ? "s" : ""}` :
          status.state === "waiting" ? `${getHours(status.startTime) < 12 ? "오전" : "오후"} ${getHours(status.startTime) > 12 ? getHours(status.startTime) - 12 : getHours(status.startTime)}:${getMinutes(status.startTime) > 9 ? getMinutes(status.startTime) : "0" + getMinutes(status.startTime)} 오픈 예정` : "게임이 종료되었습니다."} */}
      </Button>

      <div className="link-btn" style={{width: '100%', padding: '10px 0'}}>
        <button type="button" onClick={() => summonFriends(1, refCode)}>
          <LinkIcon height={20}/>친구소환
        </button>
        <button type="button" onClick={() => navigate("/playground/store")}>
          <ShopIcon height={20}/>아이템 상점
        </button>
      </div>
    </div>
  )
}

function LoadingIcon() {
  return (
    <div className="loading-circle">
      <img src={Loading} alt="loading" height={24}/>
    </div>
  )
}
import {ReactComponent as ArrowIcon} from "../../assets/icons/arrow_left.svg";
import {useLocation, useNavigate} from "react-router";
import PointImg from "../../assets/images/img_point.png";
import CouponPic from "../../assets/images/pool-slide-1.png";
import Button from "../../components/common/Button";
import {BottomPopup} from "../../components/common/Popup";
import React, {useState, useContext} from "react";
import {AlertDialog} from "../../components/common/Modal";
import ToastAlert from "../../components/common/Toast";
import {AuthContext} from "../../context/AuthContext";
import useSWR from "swr";
import {useRunTimeEnvironment} from "../../utils/hooks";

export default function CouponDetailPage() {

  const navigate = useNavigate();
  const location  = useLocation();

  const goodsId = location.state.goodsId ?? location.state.couponId;
  const name = location.state?.goodsName ?? location.state?.itemName;
  const price = location.state?.goodsPrice ?? 0;
  const img = location.state?.goodsImgUrl ?? CouponPic;
  const type = location.state?.type ?? "";
  const place = type === "수영장" ? `히든밸리 ${type}` : name.split("]")[0].substring(1);
  const isMyCoupon = location.pathname === "/user/coupons/detail";
  const epin = location.state.epin ?? null;
  const purchaseCount = location.state.purchaseCount ?? 3;

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [purchaseComplete, setPurchaseComplete] = useState(false);
  const [purchaseLimit, setPurchaseLimit] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);

  const {API_SERVER_DOMAIN, useAccessToken, fetchWithToken} = useContext(AuthContext);
  const accessToken = useAccessToken();
  const RTE = useRunTimeEnvironment();
  const fetcher = url => fetch(url).then(res => res.json());

  const { data: goodsDetail } = useSWR(location.state.goodsId && `${API_SERVER_DOMAIN}/yaongcon/goods/info?goodsId=${goodsId}`, fetcher);
  const { data: profile } = useSWR(accessToken && [`${API_SERVER_DOMAIN}/user/profile/info`, accessToken], fetchWithToken);
  const { data: code128 } = useSWR(epin && `${API_SERVER_DOMAIN}/yaongcon/barcode?epin=${epin}`, fetcher);

  const purchaseYaongcon = async () => {
    const response = await fetch(`${API_SERVER_DOMAIN}/yaongcon/purchase`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "goodsId": goodsId
      })
    });
    const jsonData = await response.json();

    setConfirmOpen(false);
    if (jsonData.result === "ok") {
      setPurchaseComplete(true);
    } else {
      console.error(jsonData);
      setAlertMessage(jsonData.message);
    }
  }

  if (!profile) return;
  if (location.state.goodsId && !goodsDetail) return;
  if (epin && !code128) return;

  return (
    <section>
      <div className="page-header header-shadow">
        <ArrowIcon onClick={() => navigate(-1)} style={{left: 24}}/>
        <h3>쿠폰 상세</h3>
      </div>

      <div className="reward-img">
        <div className="card-img" style={{background: `url(${img}) no-repeat`}}/>
        <p style={{marginBottom: 4}}>[{place}]</p>
        <p>{name}</p>
        {!isMyCoupon &&
          <div style={{display: 'flex', gap: 4, alignItems: "center"}}>
            <img src={PointImg} alt={"points"} height={24}/>
            <span style={{fontSize: 18}}>{price.toLocaleString()}</span>
          </div>
        }

        {isMyCoupon && type === "야옹콘" &&
          <>
            <div className="barcode">
              <img src={code128?.barcode} alt="바코드" />
            </div>
            <span className="barcode-text">{epin.substring(0, 4)} {epin.substring(4, 8)} {epin.substring(8)}</span>
            <Button onClick={() => {
              navigator.clipboard.writeText(`${epin}`);
              setAlertMessage("쿠폰 번호가 복사되었습니다");
            }}>복사하기</Button>
          </>
        }
      </div>

      {type === "야옹콘" && 
        <div className="coupon-guide">
          <h4>이용 안내</h4>
          <p>{goodsDetail.infos.GOODS_MEMO || goodsDetail.infos.BRAND_MEMO}</p>
          {/* <h4>유의사항</h4>
          <p>유의사항을 입력하세요. 유의사항을 입력하세요. 유의사항을 입력하세요. 유의사항을 입력하세요. 유의사항을 입력하세요.</p> */}
          {
            !isMyCoupon && 
            <Button 
              style={{marginTop: 'auto'}}
              onClick={() => {
                if (RTE === 'APP') {
                  if (purchaseCount >= 3) {
                    setPurchaseLimit(true);
                  } else {
                    setConfirmOpen(true);
                  }
                } else {
                  setAlertMessage("구매는 앱에서만 가능해요");
                }
              }}
            >
              구매하러 가기
            </Button>
          }
        </div>
      }


      <BottomPopup title=""
                   open={confirmOpen}
                   setOpen={setConfirmOpen}
                   className="purchase-confirm-popup">
        <div className="purchase-confirm">
          <p style={{marginBottom: 4}}>[{place}]</p>
          <p style={{fontWeight: 'bold'}}>{name}</p>
          <div style={{display: 'flex', gap: 4, alignItems: "center", marginTop: 12}}>
            <img src={PointImg} alt={"points"} height={24}/>
            <span style={{fontSize: 18}}>{price}</span>
          </div>
          <hr/>
          <dl>
            <dt>현재 보유 포인트</dt>
            <dd>
              <div style={{display: 'flex', gap: 4, alignItems: "center"}}>
                <img src={PointImg} alt={"points"} height={24}/>
                <span style={{fontSize: 18}}>{profile.info.point.toLocaleString()}</span>
              </div>
            </dd>
            <dt>구매 후 남은 포인트</dt>
            <dd>
              <div style={{display: 'flex', gap: 4, alignItems: "center"}}>
                <img src={PointImg} alt={"points"} height={24}/>
                <span style={{fontSize: 18}}>
                  {(profile.info.point - price).toLocaleString()}
                </span>
              </div>
            </dd>
          </dl>
          <div className="alert-action-btn">
            <Button className="prev-btn" onClick={() => setConfirmOpen(false)}>취소하기</Button>
            <Button onClick={() => {
              if (profile.info.point - price >= 0) {
                purchaseYaongcon();
              } else {
                setConfirmOpen(false);
                setAlertMessage("포인트가 부족합니다");
              }
            }}>구매하기</Button>
          </div>
        </div>
      </BottomPopup>

      <AlertDialog open={purchaseComplete} title={"구매완료"}
                   onClose={() => setPurchaseComplete(false)}
                   onCancel={() => setPurchaseComplete(false)}
                   buttonText={"쿠폰함 가기"}
                   buttonSecondText={"닫기"}
                   onOk={() => navigate("/user/coupons", {replace: true, state: {nickname: profile.info.nickname, purchased: true}})}
                   text={"쿠폰함에서 쿠폰을 확인해보세요."}/>
      
      <AlertDialog open={purchaseLimit} title={"안내"}
                   onClose={() => setPurchaseLimit(false)}
                   onCancel={() => setPurchaseLimit(false)}
                   onOk={() => setPurchaseLimit(false)}
                   buttonText={"닫기"} 
                   text={"야옹콘은 하루 최대 3회까지\r\n구매할 수 있어요"}/>

      <ToastAlert show={!!alertMessage} onClose={setAlertMessage} text={alertMessage} />

    </section>
  )
}
import "../assets/styles/layout.scss";
import {ReactComponent as MainIcon} from "../assets/icons/menu/main.svg";
import {ReactComponent as MainSelectedIcon} from "../assets/icons/menu/main_selected.svg";
import {ReactComponent as PoolIcon} from "../assets/icons/menu/pool.svg";
import {ReactComponent as PoolSelectedIcon} from "../assets/icons/menu/pool_selected.svg";
import {ReactComponent as GiftIcon} from "../assets/icons/menu/yaongcon.svg";
import {ReactComponent as GiftSelectedIcon} from "../assets/icons/menu/yaongcon_selected.svg";
import {ReactComponent as UserIcon} from "../assets/icons/menu/user.svg";
import {ReactComponent as UserSelectedIcon} from "../assets/icons/menu/user_selected.svg";
import Tory from "../assets/images/button_tory.png";
import {useNavigate} from "react-router";
import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../context/AuthContext";
import {useRunTimeEnvironment} from "../utils/hooks";
import ToastAlert from "./common/Toast";
import ReactGA from "react-ga4";

export default function Layout(props) {

  ReactGA.initialize("G-9XE06MMCQK");
  const {useAccessToken} = useContext(AuthContext);
  const navigate = useNavigate();
  const token = useAccessToken();
  const RTE = useRunTimeEnvironment();
  const menu_1_2 = [{
    name: "놀이터",
    icon: <MainIcon/>,
    iconSelected: <MainSelectedIcon/>,
    url: "/playground"
  }, {
    name: "수영장",
    icon: <PoolIcon/>,
    iconSelected: <PoolSelectedIcon/>,
    url: "/pool"
  }];
  /* const menu_1_2 = RTE === "APP" ? [{
    name: "놀이터",
    icon: <MainIcon/>,
    iconSelected: <MainSelectedIcon/>,
    url: "/playground"
  }, {
    name: "수영장",
    icon: <PoolIcon/>,
    iconSelected: <PoolSelectedIcon/>,
    url: "/pool"
  }] : [{
    name: "수영장",
    icon: <PoolIcon/>,
    iconSelected: <PoolSelectedIcon/>,
    url: "/pool"
  }, {
    name: "놀이터",
    icon: <MainIcon/>,
    iconSelected: <MainSelectedIcon/>,
    url: "/playground"
  }]; */
  const menu = [
    ...menu_1_2,
    {
      name: "야옹콘",
      icon: <GiftIcon/>,
      iconSelected: <GiftSelectedIcon/>,
      url: "/coupons"
    }, {
      name: "MY",
      icon: <UserIcon/>,
      iconSelected: <UserSelectedIcon/>,
      url: "/user"
    },
  ]

  // eslint-disable-next-line no-restricted-globals
  const selected = menu.findIndex(el => el.url === location.pathname);
  const [floatBtnLeft, setFloatBtnLeft] = useState('70%')

  function updateFloatBtn() {
    let layoutWidth = document.getElementById("main-layout").offsetWidth;
    setFloatBtnLeft(`calc(50% + ${layoutWidth/2 - 76}px)`)
  }

  useEffect(() => {
    updateFloatBtn();
    // PC에서 브라우저 화면 크기를 조절했을 때 토리 버튼 위치 조정
    window.addEventListener("resize", () => updateFloatBtn())
    return () => {
      window.removeEventListener("resize", () => updateFloatBtn())
    }
  }, [])

  return (
    <main className="layout" id="main-layout">
      {token ?
        <>
        <div className="page-container" style={{maxHeight: selected===-1 ? "100vh" : "calc(100vh - 80px)"}}>
          {props.children}
        </div>
          {selected !== -1 &&
            <>
              <nav className="nav-menu">
                {menu.map((menuItem, idx) => (
                  <button key={"nav-" + idx} type="button"
                          className={selected === idx ? "selected-page" : ""}
                          onClick={() => {
                            navigate(menuItem.url);
                          }}>
                    {selected === idx ? menuItem.iconSelected : menuItem.icon}
                    {menuItem.name}
                  </button>
                ))}
              </nav>
              {(RTE === "APP" && selected === 1) &&
                <img src={Tory} alt="tory_btn"
                     style={{left: floatBtnLeft}}
                     onClick={() => navigate("/tory-game")}
                     className="tory-float-btn"/>
              }
            </>
          }
        </>
      :
        <>{props.children}</>
      }
    </main>
  )
}
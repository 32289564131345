import ErrorBoundary from "./components/ErrorBoundary";
import Layout from "./components/Layout";
import "./assets/styles/components.scss";
import {Route, Routes, useLocation} from 'react-router';
import SwimmingPoolPage from "./pages/swimming-pool/main";
import PlaygroundPage from "./pages/playground/main";
import {FrontLayerProvider} from "./context/FrontLayerContext";
import DatePicker from "./components/common/DatePicker";
import TicketBookingPage from "./pages/swimming-pool/booking";
import CouponPage from "./pages/swimming-pool/coupon";
import LuckySpinPage from "./pages/swimming-pool/lucky-spin";
import TicketPage from "./pages/swimming-pool/ticket";
import TicketCancel from "./pages/swimming-pool/ticket-cancel";
import YaongconPage from "./pages/yaongcon/main";
import MyPage from "./pages/my-page/main";
import ToryGamePage from "./pages/playground/tory-game";
import ToryGameEnd from "./pages/playground/tory-game-end";
import {AuthContextProvider} from "./context/AuthContext";
import LoginPage from "./pages/login";
import NotificationsPage from "./pages/playground/notification";
import ItemStore from "./pages/playground/item-store";
import RewardDetailPage from "./pages/playground/reward-detail";
import CouponDetailPage from "./pages/yaongcon/coupon-detail";
import MyPointsPage from "./pages/my-page/my-points";
import MyVictoriesPage from "./pages/my-page/my-victories";
import SettingsPage from "./pages/my-page/settings";
import PolicyPage, {AgreementDetail} from "./pages/my-page/policy";
import DeleteAccountPage from "./pages/my-page/delete-account";
import OAuth from "./pages/OAuth";
import KakaoCallback from "./pages/kakao-callback";
import GotoAppStore from "./pages/goto-appstore";
import {useRef} from "react";
import SummonerAddPage from "./pages/my-page/summoner";

function App() {

  const location = useLocation();
  const lastViewedSlide = useRef();


  return (
    <ErrorBoundary>
      <AuthContextProvider>
        <FrontLayerProvider>
          <Layout>
            <Routes>
              <Route path={"/"} element={<LoginPage />}/>
              <Route path={"/login"} element={<LoginPage />}/>
              <Route path={"/playground"} element={<PlaygroundPage />}/>
              <Route path={"/notifications"} element={<NotificationsPage />}/>
              <Route path={"/playground/store"} element={<ItemStore />}/>
              <Route path={"/playground/reward"} element={<RewardDetailPage />}/>
              <Route path={"/tory-game"} element={<ToryGamePage />}/>
              <Route path={"/tory-game-end"} element={<ToryGameEnd />}/>
              <Route path={"/pool"} element={<SwimmingPoolPage lastViewedSlide={lastViewedSlide} />}/>
              <Route path={"/pool/booking"} element={<TicketBookingPage />}/>
              <Route path={"/pool/coupon"} element={<CouponPage />}/>
              <Route path={"/pool/booking/success"} element={<LuckySpinPage isAfterBooking={true} />}/>
              <Route path={"/pool/lucky-spin"} element={<LuckySpinPage isAfterBooking={false} />}/>
              <Route path={"/pool/ticket"} element={<TicketPage/>}/>
              <Route path={"/pool/ticket/cancel"} element={<TicketCancel/>}/>
              <Route path={"/coupons"} element={<YaongconPage />}/>
              <Route path={"/coupons/detail"} element={<CouponDetailPage />}/>
              <Route path={"/user"} element={<MyPage />}/>
              <Route path={"/user/points"} element={<MyPointsPage />}/>
              <Route path={"/user/coupons"} element={<CouponPage myPage={true} />}/>
              <Route path={"/user/coupons/detail"} element={<CouponDetailPage />}/>
              <Route path={"/user/victories"} element={<MyVictoriesPage />}/>
              <Route path={"/user/summoner"} element={<SummonerAddPage />}/>
              <Route path={"/user/settings"} element={<SettingsPage />}/>
              <Route path={"/user/policy"} element={<PolicyPage />}/>
              <Route path={"/user/policy/terms-of-use"} element={<AgreementDetail type={"service"}/>}/>
              <Route path={"/user/policy/collection-and-use"} element={<AgreementDetail type={"collection"}/>}/>
              <Route path={"/user/policy/processing"} element={<AgreementDetail type={"processing"}/>}/>
              <Route path={"/user/delete"} element={<DeleteAccountPage />}/>
              <Route path={"/oauth"} element={<OAuth />}/>
              <Route path={"/kakaolink/callback"} element={<KakaoCallback />}/>
              <Route path={"/download"} element={<GotoAppStore />}/>
            </Routes>
          </Layout>
          {["/pool", "/pool/booking"].includes(location.pathname) && <DatePicker/>}
        </FrontLayerProvider>
      </AuthContextProvider>
    </ErrorBoundary>
  );
}

export default App;

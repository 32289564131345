/* eslint-disable */
import React, {createContext, useEffect, useState} from "react";
import {useRunTimeEnvironment} from "../utils/hooks";
import {useNavigate} from "react-router";
const RTE = useRunTimeEnvironment();

export const AuthContext = createContext({});

export const AuthContextProvider = ({children}) => {

  // const API_SERVER_DOMAIN = "http://dev-api.yaongstore.com";
  const API_SERVER_DOMAIN = "https://api.yaongstore.com";
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [appInfo, setAppInfo] = useState(null);
  let isLocked = false;

  const fetchWithToken = ([url, token]) => fetch(url, {
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    }
  }).then(res => res.json());

  const getAccessToken = async () => {
    const now = new Date();
    const token = JSON.parse(localStorage.getItem("accessToken"));

    // 토큰 만료 시간이 10분 이하면 재발급 요청
    // debug: 토큰 6분 후 만료 // 5040000
    if (token && now.getTime() > (token.expiry - 600000) && !isLocked) {
      // console.debug("토큰 재발행 시작");
      isLocked = true;
      const response = await fetch(`${API_SERVER_DOMAIN}/auth/token/refresh`, {
        headers: {
          Authorization: `RefreshToken ${localStorage.getItem("refreshToken")}`
        }
      });
      const jsonData = await response.json();
      if (jsonData.result == "ok") {
        setAccessToken(jsonData.token.accessToken, jsonData.token.accessTokenExpiresIn, jsonData.token.refreshToken);
        isLocked = false;
        // console.debug("토큰 재발행 완료");
        return jsonData.token.accessToken;
      } else {
        return null;
      }
    }

    // 토큰이 있음
    if (token) {
      // 유효성 검사
      const response = await fetch(`${API_SERVER_DOMAIN}/auth/user/check`, {
        headers: {
          "Authorization": `Bearer ${token.value}`,
          "Content-Type": "application/json",
        }
      });
      const jsonData = await response.json();
      // 유효하지 않음
      if (jsonData.status === 401) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        setToken(null);
        return null;
      }

      return token.value;
    }
    
    return null;
  }

  const setAccessToken = (accessToken, accessTokenExpiresIn, refreshToken) => {
    const now = new Date();
    const token = {
      value: accessToken,
      expiry: now.getTime() + accessTokenExpiresIn
    }
    localStorage.setItem("accessToken", JSON.stringify(token));
    localStorage.setItem("refreshToken", refreshToken);
  }

  const signIn = (accessToken, accessTokenExpiresIn, refreshToken) => {
    setAccessToken(accessToken, accessTokenExpiresIn, refreshToken);
    // const home = RTE === "APP" ? "/playground" : "/pool";
    navigate("/playground");
  }

  // 테스트 로그인. 토큰을 요청함.
  const signInTest = (loginType, token) => {
    fetch(`${API_SERVER_DOMAIN}/auth/login`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        login_type: loginType,
        token: token,
      }),
    })
    .then(response => response.json())
    .then(obj => {
      if (obj.result == "ok") {
        setAccessToken(obj.token.accessToken, obj.token.accessTokenExpiresIn, obj.token.refreshToken);
  
        navigate("/pool", {state: {firstLogin: false}});
      } else {
        console.error("Login error.");
      }
    });
  };

  const signOut = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");

    setToken(null);
    navigate("/login");
  };

  const useAccessToken = () => {
    /* (async () => {
      const accessToken = await getAccessToken();
      setToken(accessToken);
    })(); */

    return token;
  }

  useEffect(() => {
    getAccessToken().then(accessToken => {
      if (!accessToken && location.pathname !== "/login" && location.pathname !== "/user/policy/terms-of-use" && location.pathname !== "/download") {
        navigate("/login", {replace: true});
      } else {
        setToken(accessToken);
      }
    });
  }, [location.pathname])

  useEffect(()=>{
    const step = async () => {
      /**
       * 앱 정보 가져오는 방식입니다.
       * 
       * appName: 야옹상회 {String}
       * packageName: com.yaong.app {String}
       * version: 240529 {String}
       * buildVersion: 4 {String}
       * 
       * 요런식으로 들어옵니다. 참고해주세요!
       * */
      const info = await window.flutter_inappwebview.callHandler("getAppInfo");
      setAppInfo(info);
    }

    if (RTE === "APP") {
      step();
    }
  },[])


  return (
    <AuthContext.Provider
      value={{
        API_SERVER_DOMAIN,
        useAccessToken,
        fetchWithToken,
        signIn,
        signInTest,
        signOut,
        appInfo
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
import React from "react";
import Button from "./Button";

export default function ModalDialog(props) {
  const {
    open = false,
    title,
    onCancel = () => {},
    className = "",
    ...otherProps
  } = props;

  const onClickOutside = (e) => {
    if (e.target === document.getElementById("dialog")) onCancel();
  };

  if (open) {
    return (
      <div id="dialog" className={"dialog-bg " + className}
           {...otherProps}
           onClick={(e) => onClickOutside(e)}>
        <div className="dialog-box">
          <div className="dialog-title">
            <h3>{title ?? "Dialog"}</h3>
          </div>
          <div className="dialog-content">
            {props.children}
          </div>
        </div>
      </div>
    );
  } else return <></>;
}


export function AlertDialog(props) {
  const {
    open = false,
    title,
    text = "",
    buttonText = "",
    buttonSecondText,
    onClose = () => {},
    onCancel = () => {},
    className = "",
    onOk = () => {},
    ...otherProps
  } = props;

  const onClickOutside = (e) => {
    if (e.target === document.getElementById("dialog")) onClose();
  };

  if (open) {
    return (
      <div id="dialog" className={"dialog-bg " + className}
           {...otherProps}
           onClick={(e) => onClickOutside(e)}>
        <div className="dialog-box alert-dialog-box">
          <div className="dialog-title">
            <h3>{title ?? "Dialog"}</h3>
          </div>
          <div className="dialog-content alert-dialog-content">
            <p>{text}</p>
            <Button onClick={onOk}>{buttonText}</Button>
            {buttonSecondText && <Button onClick={onCancel}>{buttonSecondText}</Button>}
          </div>
        </div>
      </div>
    );
  } else return <></>;
}